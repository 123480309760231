module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-T3NQHD","includeInDevelopment":true,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Deutsch LA","short_name":"DLA","start_url":"/","background_color":"#FF4401","theme_color":"#FF4401","display":"browser","icon":"src/images/svg/deutsch-la-favicon.svg","theme_color_in_head":false,"icon_options":{"purpose":"maskable"},"legacy":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d77aafa7abe274676c99b06a28001d82"},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://deutschla.com"},
    },{
      plugin: require('../node_modules/gatsby-source-deutsch-hr-api/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://careers.deutschla.com/api/v1","careersEndpoint":"/positions"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-ts-config/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    }]
