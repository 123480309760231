import './src/styles/styles.css';
import './node_modules/slick-carousel/slick/slick.css';
import './node_modules/slick-carousel/slick/slick-theme.css';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { MotionPathPlugin } from 'gsap/dist/MotionPathPlugin';
import { CSSRulePlugin } from 'gsap/CSSRulePlugin';
import gsap from 'gsap';

if (typeof window !== 'undefined') {
  gsap.registerPlugin(ScrollTrigger, CSSRulePlugin, MotionPathPlugin);
}

function doInitialLoaderAnimation() {
  const animationOnMilliseconds = 500;
  const initialLoader = document.getElementById('___initialLoader');
  const wrappedComponent = document.getElementById('___wrappedLoadingComponent');
  if (initialLoader && wrappedComponent) {
    setTimeout(function () {
      initialLoader.style.opacity = '0';
      wrappedComponent.style.opacity = '1';
    }, animationOnMilliseconds);
    setTimeout(function () {
      initialLoader.style.display = 'none';
    }, animationOnMilliseconds + 500);
  }
}

function replaceInText(element: Node, pattern: string, replacement: string) {
  for (const node of element.childNodes) {
    switch (node.nodeType) {
      case Node.ELEMENT_NODE:
        replaceInText(node, pattern, replacement);
        break;
      case Node.TEXT_NODE:
        node.textContent = node.textContent.replace(pattern, replacement);
        break;
      case Node.DOCUMENT_NODE:
        replaceInText(node, pattern, replacement);
        break;
      default:
        break;
    }
  }
}

export const onRouteUpdate = (): void => {
  // eslint-disable-next-line max-statements-per-line
  setTimeout(function () {
    replaceInText(document.body, 'Deutsch LA', 'Deutsch\u00a0LA');
  }, 200);
  doInitialLoaderAnimation();
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const shouldUpdateScroll = ({ routerProps: { location }, getSavedScrollPosition }) => {
  const { pathname } = location;
  const currentPosition = getSavedScrollPosition(location);
  const scrollToTopRoutes = ['/'];
  if (scrollToTopRoutes.indexOf(pathname) !== -1 || pathname.includes('/what-we-do')) {
    window.scrollTo(0, 0);
  } else {
    window.scrollTo(...(currentPosition ?? [0, 0]));
  }

  return false;
};
